import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import { getAllLakesConductance } from '../services/lakeService';
import { LakeConductance } from '../types/LakeConductance';
import { Reload } from '../types/Reload';

interface LakeDataWithTimestamp extends LakeConductance {
    timestamp: number;
}

const LakeConductanceChart: React.FC<Reload> = ({ reload }) => {
    const [data, setData] = useState<{
        [key: string]: LakeDataWithTimestamp[];
    }>({});
    const [selectedLake, setSelectedLake] = useState<string | null>(null);

    useEffect(() => {
        const loadLakeData = async () => {
            try {
                const lakeData = await getAllLakesConductance();
                console.log('Fetched data:', lakeData);

                const groupedData: { [key: string]: LakeDataWithTimestamp[] } =
                    lakeData.reduce(
                        (acc, lake) => {
                            const lakeName = lake.name;
                            if (!acc[lakeName]) {
                                acc[lakeName] = [];
                            }
                            acc[lakeName].push({
                                ...lake,
                                timestamp: new Date(lake.sampleDate).getTime(),
                            });
                            return acc;
                        },
                        {} as { [key: string]: LakeDataWithTimestamp[] },
                    );

                console.log('Grouped data:', groupedData);

                for (const lakeName in groupedData) {
                    const sortedData = sortByTimeStamp(groupedData[lakeName]);
                    groupedData[lakeName] = get10MostRecentSamples(sortedData);
                }

                console.log('Processed grouped data:', groupedData);

                setData(groupedData);

                setSelectedLake(Object.keys(groupedData)[0] || null);
            } catch (error) {
                console.error('Error fetching lake data:', error);
            }
        };

        loadLakeData();
    }, [reload]);

    const sortByTimeStamp = (
        data: LakeDataWithTimestamp[],
    ): LakeDataWithTimestamp[] => {
        return data.sort((a, b) => a.timestamp - b.timestamp);
    };

    const get10MostRecentSamples = (
        data: LakeDataWithTimestamp[],
    ): LakeDataWithTimestamp[] => {
        return data.slice(-10);
    };

    const handleLakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLake(event.target.value);
    };

    return (
        <div>
            <h1>Lake Conductance Over Time of 10 Most Recent Samples</h1>

            <label htmlFor="lake-select">Select Lake: </label>
            <select
                id="lake-select"
                value={selectedLake ?? ''}
                onChange={handleLakeChange}
            >
                {Object.keys(data).map((lakeName) => (
                    <option key={lakeName} value={lakeName}>
                        {lakeName}
                    </option>
                ))}
            </select>

            <ResponsiveContainer width="100%" height={400}>
                <LineChart
                    data={selectedLake ? data[selectedLake] : []}
                    key={selectedLake}
                >
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(timestamp) =>
                            new Date(timestamp).toLocaleDateString()
                        }
                    />
                    <YAxis
                        domain={[5, 30]}
                        padding={{ top: 20, bottom: 20 }}
                        label={{
                            value: 'Conductance (uS/cm)',
                            angle: -90,
                            position: 'insideLeft',
                        }}
                    />
                    <Tooltip
                        labelFormatter={(timestamp) =>
                            new Date(timestamp).toLocaleDateString()
                        }
                    />
                    <Legend />

                    {selectedLake && (
                        <Line
                            dataKey="conductance"
                            name={selectedLake}
                            type="monotone"
                            stroke="#8884d8"
                            dot={true}
                            connectNulls={true}
                        />
                    )}
                    <ReferenceLine
                        y={30}
                        label="Lower Limit (30)"
                        stroke="red"
                        strokeDasharray="3 3"
                    />
                    <ReferenceLine
                        y={800}
                        label="Upper Limit (800)"
                        stroke="red"
                        strokeDasharray="3 3"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LakeConductanceChart;
