import React, { useEffect, useState } from 'react';
import {
    LineChart,
    Line,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    ReferenceLine,
} from 'recharts';
import { getAllLakesDIC } from '../services/lakeService';
import { LakeDIC } from '../types/LakeDIC';
import { Reload } from '../types/Reload';

interface LakeDataWithTimestamp extends LakeDIC {
    timestamp: number;
}

const LakeDICChart: React.FC<Reload> = ({ reload }) => {
    const [data, setData] = useState<{
        [key: string]: LakeDataWithTimestamp[];
    }>({});
    const [selectedLake, setSelectedLake] = useState<string | null>(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);

    useEffect(() => {
        const loadLakeData = async () => {
            try {
                setLoading(true);
                const lakeData = await getAllLakesDIC();
                console.log('This is the data, ', lakeData);

                const groupedData: { [key: string]: LakeDataWithTimestamp[] } =
                    lakeData.reduce(
                        (acc, lake) => {
                            const lakeName = lake.name;
                            if (!acc[lakeName]) {
                                acc[lakeName] = [];
                            }
                            acc[lakeName].push({
                                ...lake,
                                timestamp: new Date(lake.sampleDate).getTime(),
                            });
                            return acc;
                        },
                        {} as { [key: string]: LakeDataWithTimestamp[] },
                    );

                for (const lakeName in groupedData) {
                    const sortedData = groupedData[lakeName].sort(
                        (a, b) => a.timestamp - b.timestamp,
                    );
                    groupedData[lakeName] = get10MostRecentSamples(sortedData);
                }

                setData(groupedData);
                setSelectedLake(Object.keys(groupedData)[0] || null);
            } catch (error) {
                console.error('Error fetching lake data:', error);
                setError('Failed to fetch lakes');
            } finally {
                setLoading(false);
            }
        };

        loadLakeData();
    }, [reload]);

    const get10MostRecentSamples = (
        data: LakeDataWithTimestamp[],
    ): LakeDataWithTimestamp[] => {
        return data.slice(-10);
    };

    const handleLakeChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setSelectedLake(event.target.value);
    };

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <div>
            <h1>Lake DIC Over Time of 10 Most Recent Samples</h1>

            <label htmlFor="lake-select">Select Lake: </label>
            <select
                id="lake-select"
                value={selectedLake ?? ''}
                onChange={handleLakeChange}
            >
                {Object.keys(data).map((lakeName) => (
                    <option key={lakeName} value={lakeName}>
                        {lakeName}
                    </option>
                ))}
            </select>

            <ResponsiveContainer width="100%" height={400}>
                <LineChart data={selectedLake ? data[selectedLake] : []}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis
                        dataKey="timestamp"
                        type="number"
                        scale="time"
                        domain={['dataMin', 'dataMax']}
                        tickFormatter={(timestamp) =>
                            new Date(timestamp).toLocaleDateString()
                        }
                    />
                    <YAxis
                        domain={['auto', 20]}
                        label={{
                            value: 'DIC (mg/L)',
                            angle: -90,
                            position: 'insideLeft',
                        }}
                    />
                    <Tooltip
                        labelFormatter={(timestamp) =>
                            new Date(timestamp).toLocaleDateString()
                        }
                    />
                    <Legend />

                    {selectedLake && (
                        <Line
                            dataKey="dic"
                            name={selectedLake}
                            type="monotone"
                            stroke="black"
                            dot={true}
                        />
                    )}
                    <ReferenceLine
                        y={20}
                        label="Upper Limit (20 mg/L)"
                        stroke="red"
                        strokeDasharray="3 3"
                    />
                </LineChart>
            </ResponsiveContainer>
        </div>
    );
};

export default LakeDICChart;
