import './App.css';
import './global.css';
import SplashPage from './components/SplashPage';
import PhTnTable from './components/PhTnTable';
import TopChlorideTable from './components/TopChlorideTable';
import LakeChemistryDetails from './components/LakeChemistryDetails';
import { Button } from './components/ui/Button';
import React, { useState } from 'react';
import MyBarChart from './components/pHChart';
import LakeConductanceChart from './components/ConductanceLineChart';
import LakePhChart from './components/PhLineChart';
import LakeDOCChart from './components/DOCLineChart';
import LakeDICChart from './components/DICLineChart';

function App() {
    const [reload, setReload] = useState(0);

    const reloadButtonClicked = () => {
        setReload((prev) => prev + 1);
    };

    return (
        <>
            <SplashPage />
            <div className="flex justify-center">
                <Button onClick={reloadButtonClicked}>Reload</Button>
            </div>
            <div className="flex mt-7 max-h-screen">
                <div className="flex-grow max-w-[33%] ">
                    <PhTnTable reload={reload} />
                </div>

                <div className="flex-grow max-w-[33%] ">
                    <TopChlorideTable reload={reload} />
                </div>
                <div className="flex-grow max-w-[33%] ">
                    <div className="text-center font-bold text-2xl mb-4">
                        pH Levels for all Lakes
                    </div>
                    <MyBarChart reload={reload} />
                </div>
            </div>

            {/* Add the LakeSampleDetails component here */}
            <div className="mt-7">
                <LakeChemistryDetails reload={reload} />
            </div>

            {/* Add the LakeConductanceChart component here */}
            <div className="mt-7">
                <div className="text-center font-bold text-2xl mb-4">
                    Conductance Levels for Recent 10 Lakes
                </div>
                <LakeConductanceChart reload={reload} />
                <div className="text-center font-bold text-2xl mb-4">
                    pH Levels for Recent 10 Lakes
                </div>
                <div>
                    <LakePhChart reload={reload} />
                </div>
                <div className="text-center font-bold text-2xl mb-4">
                    Dissolved Organic Carbon Levels for Recent 10 Lakes
                </div>
                <div>
                    <LakeDOCChart reload={reload} />
                </div>
                <div className="text-center font-bold text-2xl mb-4">
                    Dissolved Inorganic Carbon Levels for Recent 10 Lakes
                </div>
                <div>
                    <LakeDICChart reload={reload} />
                </div>
            </div>
        </>
    );
}

export default App;
