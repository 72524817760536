import React, { useEffect, useState } from 'react';
import { getAllLakes } from '../services/lakeService';
import {
    Table,
    TableBody,
    // TableCaption,
    TableCell,
    TableHead,
    TableHeader,
    TableRow,
} from './ui/Table';
import { Reload } from '../types/Reload';
import { Lake } from '../types/Lake';

const PhTnTable: React.FC<Reload> = ({ reload }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [lakes, setLakes] = useState<Lake[]>([]);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setLoading(true);
                const lakeList = await getAllLakes();
                console.log('Fetched lakes:', lakeList);
                if (Array.isArray(lakeList)) {
                    setLakes(lakeList);
                } else {
                    setError('Failed to fetch lakes');
                }
            } catch (err) {
                console.error('Error fetching lakes:', err);
                setError('Failed to fetch lakes');
            } finally {
                setLoading(false);
            }
        };
        fetchNotes();
    }, [reload]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <>
            <div className="text-center font-bold text-2xl mb-4">
                pH vs TN of Lakes
            </div>
            <div className="px-12">
                <Table>
                    <TableHeader>
                        <TableRow>
                            <TableHead className="w-[30%]">Lake Name</TableHead>
                            <TableHead>pH</TableHead>
                            <TableHead>TN (mg/L)</TableHead>
                            <TableHead className="text-right">
                                Sample Date
                            </TableHead>
                        </TableRow>
                    </TableHeader>
                </Table>
            </div>

            <div className="p-4 m-4 border-4 border-hsl(var(--primary)) rounded-[var(--radius)] max-h-[80%] overflow-y-auto block">
                <Table>
                    <TableBody>
                        {lakes.map((lake) => (
                            <TableRow key={lake.id}>
                                <TableCell className="w-[30%]">
                                    {lake.name}
                                </TableCell>
                                <TableCell>{lake.ph}</TableCell>
                                <TableCell>{lake.tn}</TableCell>
                                <TableCell className="text-right">
                                    {lake.sampleDate}
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </div>
        </>
    );
};

export default PhTnTable;
