import React from 'react';

const SplashPage: React.FC = () => {
    return (
        <div style={styles.container}>
            <h1 style={styles.heading}>Welcome to the Water Quality Tracker</h1>
            <p style={styles.text}>
                Whether you are a policy maker, environmental scientist, or an
                industrial facility manager, this app provides powerful insights
                into the relationship between industrial wastewater discharge
                and water quality in streams and inland lakes.
            </p>
            <p style={styles.text}>
                Designed to promote transparency and sustainability, it empowers
                users to track water quality trends over time and pinpoint the
                impact of industrial activity.
            </p>
        </div>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column' as const,
        alignItems: 'center',
        justifyContent: 'center',
        // height: '100vh',
        // backgroundColor: '#f0f8ff',
        padding: '20px',
    },
    heading: {
        fontSize: '2.5rem',
        marginBottom: '20px',
        textAlign: 'center' as const,
    },
    text: {
        fontSize: '1.2rem',
        textAlign: 'center' as const,
        maxWidth: '800px',
        marginBottom: '10px',
    },
};

export default SplashPage;
