import { Lake } from '../types/Lake';
import { LakeComment } from '../types/LakeComment';
import { LakeConductance } from '../types/LakeConductance';
import { LakePh } from '../types/LakePh';
import { LakeDOC } from '../types/LakeDOC';
import { LakeDIC } from '../types/LakeDIC';

const LAKE_URL = 'api/lakes';
const LAKE_COMMENTS_URL = 'api/lakeComments'; // Updated endpoint for lake comments
const LAKE_PH = 'api/lakes/ph';
const LAKE_CONDUCTANCE_URL = 'api/lakes/conductance';
const LAKE_DOC = 'api/lakes/doc';
const LAKE_DIC = 'api/lakes/dic';

// Fetch all lakes
export const getAllLakes = async (): Promise<Lake[]> => {
    console.log('Fetching all lakes from:', `${LAKE_URL}`);
    try {
        const response = await fetch(`${LAKE_URL}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('All Lakes API response:', data);
        return data;
    } catch (error) {
        console.error('Error fetching lakes:', error);
        return [];
    }
};

// Fetch top 10 lakes based on chloride levels
export const getTop10ChlorideLakes = async (): Promise<Lake[]> => {
    console.log('Fetching top 10 chloride lakes from:', `${LAKE_URL}`);
    try {
        const response = await fetch(`${LAKE_URL}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Top 10 Chloride API response:', data);

        const top10Lakes = data
            .sort((a: Lake, b: Lake) => b.chloride - a.chloride) // Sort descending by chloride
            .slice(0, 10); // Get the top 10 lakes

        return top10Lakes;
    } catch (error) {
        console.error('Error fetching lakes:', error);
        return [];
    }
};

// Fetch lake comments
export const getLakeComments = async (): Promise<LakeComment[]> => {
    console.log('Fetching lake comments from:', `${LAKE_COMMENTS_URL}`); // Log the full endpoint
    try {
        const response = await fetch(`${LAKE_COMMENTS_URL}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Lake Comments API response:', data); // Log the response data
        return data; // Adjust based on your API response structure
    } catch (error) {
        console.error('Error fetching lake comments:', error);
        return [];
    }
};

export const getRecentPHLakes = async (): Promise<Lake[]> => {
    console.log('Fetching recent pH levels from lakes from:', `${LAKE_URL}`);
    try {
        const response = await fetch(`${LAKE_URL}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Recent pH API response:', data);

        const mostRecentSamplesMap = new Map<string, Lake>();

        data.forEach((sample: Lake) => {
            const lakeName = sample.name;
            const existingSample = mostRecentSamplesMap.get(lakeName);

            if (
                !existingSample ||
                new Date(sample.sampleDate) >
                    new Date(existingSample.sampleDate)
            ) {
                mostRecentSamplesMap.set(lakeName, sample);
            }
        });

        const mostRecentSamplesArray = Array.from(
            mostRecentSamplesMap.values(),
        );

        return mostRecentSamplesArray;
    } catch (error) {
        console.error('Error fetching lakes:', error);
        return [];
    }
};

// Fetch all lakes conductance
export const getAllLakesConductance = async (): Promise<LakeConductance[]> => {
    console.log(
        'Fetching all lakes conductance from:',
        `${LAKE_CONDUCTANCE_URL}`,
    );
    try {
        const response = await fetch(`${LAKE_CONDUCTANCE_URL}`);
        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        console.log('Conductance API response:', data); // Log the response data
        return data;
    } catch (error) {
        console.error('Error fetching lakes conductance:', error);
        return [];
    }
};

export const getAllLakesDOC = async (): Promise<LakeDOC[]> => {
    try {
        console.log('Fetching all DOC levels from lakes from:', `${LAKE_DOC}`);

        const response = await fetch(`${LAKE_DOC}`);
        const data = await response.json();

        console.log('Raw data:', data);

        const groupedData = data.reduce(
            (acc: { [key: string]: LakeDOC[] }, lakeSample: LakeDOC) => {
                const lakeName = lakeSample.name;
                if (!acc[lakeName]) {
                    acc[lakeName] = [];
                }
                acc[lakeName].push(lakeSample);
                return acc;
            },
            {},
        );

        const result: LakeDOC[] = [];
        Object.keys(groupedData).forEach((lakeName) => {
            const samples = groupedData[lakeName]
                .sort(
                    (a: LakeDOC, b: LakeDOC) =>
                        new Date(b.sampleDate).getTime() -
                        new Date(a.sampleDate).getTime(),
                )
                .filter((sample: LakeDOC, index: number, self: LakeDOC[]) => {
                    const timestamp = new Date(sample.sampleDate).getTime();
                    return (
                        self.findIndex(
                            (s) =>
                                new Date(s.sampleDate).getTime() === timestamp,
                        ) === index
                    );
                });

            console.log(`Samples for ${lakeName}:`, samples);

            result.push(...samples);
        });

        console.log('Processed result:', result);

        return result;
    } catch (error) {
        console.error('Error fetching lakes DOC:', error);
        return [];
    }
};

export const getAllLakesDIC = async (): Promise<LakeDIC[]> => {
    try {
        console.log('Fetching all DIC levels from lakes from:', `${LAKE_DIC}`);

        const response = await fetch(`${LAKE_DIC}`);
        const data = await response.json();

        console.log('Raw data:', data);

        const groupedData = data.reduce(
            (acc: { [key: string]: LakeDIC[] }, lakeSample: LakeDIC) => {
                const lakeName = lakeSample.name;
                if (!acc[lakeName]) {
                    acc[lakeName] = [];
                }
                acc[lakeName].push(lakeSample);
                return acc;
            },
            {},
        );

        const result: LakeDIC[] = [];
        Object.keys(groupedData).forEach((lakeName) => {
            const samples = groupedData[lakeName]
                .sort(
                    (a: LakeDIC, b: LakeDIC) =>
                        new Date(b.sampleDate).getTime() -
                        new Date(a.sampleDate).getTime(),
                )
                .filter((sample: LakeDIC, index: number, self: LakeDIC[]) => {
                    const timestamp = new Date(sample.sampleDate).getTime();
                    return (
                        self.findIndex(
                            (s) =>
                                new Date(s.sampleDate).getTime() === timestamp,
                        ) === index
                    );
                });

            console.log(`Samples for ${lakeName}:`, samples);

            result.push(...samples);
        });

        console.log('Processed result:', result);

        return result;
    } catch (error) {
        console.error('Error fetching lakes DIC:', error);
        return [];
    }
};

export const getAllLakesPh = async (): Promise<LakePh[]> => {
    try {
        console.log('Fetching all pH levels from lakes from:', `${LAKE_PH}`);

        const response = await fetch(`${LAKE_PH}`);
        const data = await response.json();

        console.log('Raw data:', data);

        const groupedData = data.reduce(
            (acc: { [key: string]: LakePh[] }, lakeSample: LakePh) => {
                const lakeName = lakeSample.name;
                if (!acc[lakeName]) {
                    acc[lakeName] = [];
                }
                acc[lakeName].push(lakeSample);
                return acc;
            },
            {},
        );

        const result: LakePh[] = [];
        Object.keys(groupedData).forEach((lakeName) => {
            const samples = groupedData[lakeName]
                .sort(
                    (a: LakePh, b: LakePh) =>
                        new Date(b.sampleDate).getTime() -
                        new Date(a.sampleDate).getTime(),
                )
                .filter((sample: LakePh, index: number, self: LakePh[]) => {
                    const timestamp = new Date(sample.sampleDate).getTime();
                    return (
                        self.findIndex(
                            (s) =>
                                new Date(s.sampleDate).getTime() === timestamp,
                        ) === index
                    );
                })
                .slice(0, 10);

            result.push(...samples);
        });

        console.log(
            'Processed data (10 most recent unique samples for each lake):',
            result,
        );

        return result;
    } catch (error) {
        console.error('Error fetching lake data:', error);
        throw error;
    }
};
