import React, { useEffect, useState } from 'react';
import { getLakeComments } from '../services/lakeService';
import { LakeComment } from '../types/LakeComment';
import { Reload } from '../types/Reload';

const LakeChemistryDetails: React.FC<Reload> = ({ reload }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [lakeComments, setLakeComments] = useState<LakeComment[]>([]);
    const [selectedLakeName, setSelectedLakeName] = useState<string | null>(
        null,
    );

    useEffect(() => {
        const fetchLakesAndComments = async () => {
            try {
                setLoading(true);
                const lakeCommentsList = await getLakeComments();
                console.log('Fetched lake comments:', lakeCommentsList); // Log comments

                if (Array.isArray(lakeCommentsList)) {
                    setLakeComments(lakeCommentsList);
                } else {
                    setError('Failed to fetch lake comments');
                }
            } catch (err) {
                console.error(err); // Changed to error log for better visibility
                setError('Failed to fetch data');
            } finally {
                setLoading(false);
            }
        };
        fetchLakesAndComments();
    }, [reload]);

    const handleLakeSelect = (lakeName: string | null) => {
        setSelectedLakeName(lakeName);
    };

    if (loading) return <p className="text-center">Loading...</p>;
    if (error) return <p className="text-red-500 text-center">{error}</p>;

    // Create an array of unique lake names for selection
    const uniqueLakeNames = lakeComments
        .map((lake) => lake.name)
        .filter((name, index, self) => self.indexOf(name) === index);

    // Filter the lakes based on the selected lake name
    const selectedLakeSamples = lakeComments.filter(
        (lake) => lake.name === selectedLakeName,
    );

    return (
        <div className="p-4 rounded-lg shadow-lg border border-gray-200 bg-white mt-4">
            <h1 className="text-2xl font-bold text-center">
                Lake Chemistry Details
            </h1>

            {/* Display buttons for selecting a lake */}
            <div className="lake-list text-center mt-4">
                {uniqueLakeNames.length > 0 ? (
                    uniqueLakeNames.map((lakeName) => (
                        <button
                            key={lakeName}
                            onClick={() => handleLakeSelect(lakeName)}
                            className={`bg-blue-500 text-white px-4 py-2 rounded-lg mx-2 mb-2 hover:bg-blue-600 transition ${
                                selectedLakeName === lakeName
                                    ? 'bg-blue-700'
                                    : ''
                            }`}
                        >
                            {lakeName}
                        </button>
                    ))
                ) : (
                    <p>No lakes available</p>
                )}
            </div>

            {/* Display selected lake sample details */}
            {selectedLakeName && selectedLakeSamples.length > 0 && (
                <div className="lake-details mt-6">
                    <h2 className="text-xl font-semibold">
                        {selectedLakeName}
                    </h2>

                    {selectedLakeSamples.map((lake, index) => (
                        <div
                            key={index}
                            className="sample border p-4 rounded-lg mt-4 bg-gray-50"
                        >
                            <p>
                                <strong>Sample Date: </strong>
                                {lake.sampleDate}
                            </p>
                            <p>
                                <strong>Parameter: </strong> {lake.parameter}
                            </p>
                            <p>
                                <strong>Value: </strong> {lake.value}
                            </p>
                            <p>
                                <strong>Result Comment: </strong>{' '}
                                {lake.resultComment}
                            </p>
                        </div>
                    ))}
                </div>
            )}

            {!selectedLakeName && (
                <p className="text-center mt-4">
                    Please select a lake to view its samples and comments.
                </p>
            )}
        </div>
    );
};

export default LakeChemistryDetails;
