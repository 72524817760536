import React, { useEffect, useState } from 'react';
import {
    BarChart,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
} from 'recharts';
import { getRecentPHLakes } from '../services/lakeService';
import { Reload } from '../types/Reload';
import { Lake } from '../types/Lake';

interface ChartData {
    name: string;
    pH: number;
}

const MyBarChart: React.FC<Reload> = ({ reload }) => {
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState<string | null>(null);
    const [chartData, setChartData] = useState<ChartData[]>([]);

    useEffect(() => {
        const fetchNotes = async () => {
            try {
                setLoading(true);
                const lakeList = await getRecentPHLakes();
                console.log('Fetched lakes:', lakeList);
                if (Array.isArray(lakeList)) {
                    const formattedData = lakeList.map((lake: Lake) => ({
                        name: lake.name,
                        pH: lake.ph,
                    }));
                    setChartData(formattedData);
                } else {
                    setError('Failed to fetch lakes');
                }
            } catch (err) {
                console.error('Error fetching lakes:', err);
                setError('Failed to fetch lakes');
            } finally {
                setLoading(false);
            }
        };
        fetchNotes();
    }, [reload]);

    if (loading) return <p>Loading...</p>;
    if (error) return <p>{error}</p>;

    return (
        <ResponsiveContainer width="100%" height={400}>
            <BarChart data={chartData}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis
                    dataKey="name"
                    tick={{ fontSize: 8 }}
                    angle={-25}
                    textAnchor="end"
                />
                <YAxis />
                <Tooltip />
                <Legend />
                <Bar dataKey="pH" fill="blue" />
            </BarChart>
        </ResponsiveContainer>
    );
};

export default MyBarChart;
